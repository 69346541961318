<template>
	<div class="report">
		<div class="complianceHeader">
			<div class="title tool-drag"></div>
			<div class="closeBtn">
				<i @click="closeCluster" class="el-icon-close"></i>
			</div>
		</div>
		<div class="reportContent">
			<div class="left">
				<div class="complianceHeader">
					<div class="title tool-drag">Auto-Fill Report Data</div>
					<div class="closeBtn button" @click="IdentifyData">Identify >></div>
				</div>
				<el-input type="textarea" v-model="textarea"> </el-input>
			</div>
			<div class="right">
				<div class="complianceHeader">
					<div class="title tool-drag">
						Report Type
						<el-select class="reportselect" v-model="reportType" placeholder="请选择" @change="updateFormByReportTable">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
					<div class="closeBtn button" @click="clearAll()">Clear All</div>
					<div class="save closeBtn button" @click="saveData()">Save</div>
				</div>
				<div class="reportTypeContent">
					<report-cosp v-if="reportType === 'COSP'" ref="COSP" :textForm="form"></report-cosp>
					<report-noon v-else-if="reportType === 'Noon Report'" ref="Noon Report" :textForm="form"></report-noon>
					<report-eosp v-else-if="reportType === 'EOSP'" ref="EOSP" :textForm="form"></report-eosp>
					<report-stoppage v-else-if="reportType === 'Drift'" ref="Drift" :textForm="form"></report-stoppage>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getMasterReport, addMasterReport, updateMasterReport } from '@/api/basic';
let mustFields = [
	'vesselName',
	'imoNum',
	'callSign',
	'departurePort',
	'cospDate',
	'cospPositionLatitudeLeft',
	'cospPositionLatitudeCenter',
	'cospPositionLatitudeRight',
	'cospPositionLongitudeLeft',
	'cospPositionLongitudeCenter',
	'cospPositionLongitudeRight',
	'distanceToGo',
	'arrivalPosition',
	'positionLatitudeLeft',
	'positionLatitudeCenter',
	'positionLatitudeRight',
	'longitudeLeft',
	'longitudeCenter',
	'longitudeRight',
	'eospData',
	'timeSlr',
	'distanceSlr',
	'avgSpeedOverGroundSlr',
	'distanceSinceCosp',
	'stopPositionLatitudeLeft',
	'stopPositionLatitudeCenter',
	'stopPositionLatitudeRight',
	'stopPositionLongitudeLeft',
	'stopPositionLongitudeCenter',
	'stopPositionLongitudeRight',
	'stopDate',
	'distSlrAtStop',
	'resumePositionLatitudeLeft',
	'resumePositionLatitudeCenter',
	'resumePositionLatitudeRight',
	'resumePositionLongitudeLeft',
	'resumePositionLongitudeCenter',
	'resumePositionLongitudeRight',
	'resumeData',
	'distRemainAtResume',
	'stopReason',
	'stoppageTime',
	'brobHfo',
	'brobLsfo',
	'brobMdo',
	'brobMgo',
	'stopBrobHfo',
	'stopBrobLsfo',
	'stopBrobMdo',
	'stopBrobMgo',
	'resumeBrobHfo',
	'resumeBrobLsfo',
	'resumeBrobMdo',
	'resumeBrobMgo',
	'positionDate',
	'cospDate',
	'eospData',
];
export default {
	props: {
		simulationData: {
			type: Object,
			default: () => {},
		},
		customerId: {
			type: String,
			default: '',
		},
		voyageNo: {
			type: String,
			default: null,
		},
		typeBL: {
			type: Array,
			default: () => [],
		},
		isEdit: {
			type: Boolean,
			default: () => false,
		},
	},
	watch: {
		form: {
			handler(val) {
				this.updateFormByReportTable();
			},
		},
	},
	components: {
		ReportCosp: () => import('./report-cosp.vue'),
		ReportNoon: () => import('./report-noon.vue'),
		ReportEosp: () => import('./report-eosp.vue'),
		ReportStoppage: () => import('./report-stoppage.vue'),
	},
	data() {
		return {
			textarea: '',
			dictionary: {
				COSP: {
					ShipName: 'vesselName',
					IMOnum: 'imoNum',
					Callsign: 'callSign',
					COSPDateTime: 'cospDate',
					DraftFwd: {
						spilt: 'number',
						data: 'foreDraft',
					},
					DraftAft: {
						spilt: 'number',
						data: 'aftDraft',
					},
					MeanDraft: {
						spilt: 'number',
						data: 'midDraft',
					},
					LoadingCond: 'load',
					GmHeight: {
						spilt: 'number',
						data: 'gm',
					},
					CargoName: 'cargo',
					CargoWeight: {
						spilt: 'number',
						data: 'cargoWeight',
					},
					Displacement: {
						spilt: 'number',
						data: 'displancement',
					},
					DistArrPilot: {
						spilt: 'number',
						data: 'distanceToGo',
					},
					BROBHFO: {
						spilt: 'number',
						data: 'brobHfo',
					},
					BROBLSFO: {
						spilt: 'number',
						data: 'brobLsfo',
					},
					BROBMDO: {
						spilt: 'number',
						data: 'brobMdo',
					},
					BROBMGO: {
						spilt: 'number',
						data: 'brobMgo',
					},
					ReqETA: 'nextPortEta',
					IntdSpeed: {
						spilt: 'number',
						data: 'orderedSpeed',
					},
					PositionCOSP: {
						LAT: ['cospPositionLatitudeLeft', 'cospPositionLatitudeCenter', 'cospPositionLatitudeRight'],
						LON: ['cospPositionLongitudeLeft', 'cospPositionLongitudeCenter', 'cospPositionLongitudeRight'],
					},
				},
				'Noon Report': {
					// cosp复制过来的
					ShipName: 'vesselName',
					IMOnum: 'imoNum',
					Callsign: 'callSign',
					COSPDateTime: 'cospDate',
					DraftFwd: {
						spilt: 'number',
						data: 'foreDraft',
					},
					DraftAft: {
						spilt: 'number',
						data: 'aftDraft',
					},
					MeanDraft: {
						spilt: 'number',
						data: 'midDraft',
					},
					LoadingCond: 'load',
					GmHeight: {
						spilt: 'number',
						data: 'gm',
					},
					CargoName: 'cargo',
					CargoWeight: 'cargoWeight',
					Displacement: {
						spilt: 'number',
						data: 'displancement',
					},
					DistArrPilot: {
						spilt: 'number',
						data: 'distanceToGo',
					},
					BROBHFO: {
						spilt: 'number',
						data: 'brobHfo',
					},
					BROBLSFO: {
						spilt: 'number',
						data: 'brobLsfo',
					},
					BROBMDO: {
						spilt: 'number',
						data: 'brobMdo',
					},
					BROBMGO: {
						spilt: 'number',
						data: 'brobMgo',
					},
					IntdSpeed: {
						spilt: 'number',
						data: 'orderedSpeed',
					},
					// 独有
					Position: {
						LAT: ['positionLatitudeLeft', 'positionLatitudeCenter', 'positionLatitudeRight'],
						LON: ['longitudeLeft', 'longitudeCenter', 'longitudeRight'],
					},
					ReportDateTime: 'positionDate',
					AvgSpeed: {
						spilt: 'number',
						data: 'avgSpeedOverGroundSlr',
					},
					Course: {
						spilt: 'number',
						data: 'course',
					},
					DistSLR: {
						spilt: 'number',
						data: 'distanceSlr',
					},
					DistRemain: {
						spilt: 'number',
						data: 'distanceToGo',
					},
					MePower: {
						spilt: 'number',
						data: 'mePowerSlr',
					}, // 数组
					AvgRPM: {
						spilt: 'number',
						data: 'avgRpmSlr',
					},
					Slip: {
						spilt: 'number',
						data: 'slipSlr',
					},
					SteamTimeSLR: {
						spilt: 'number',
						data: 'timeSlr',
					},
					MeLoadSLR: {
						spilt: 'number',
						data: 'mcr'
					},
					WindBF: {
						spilt: 'number',
						data: 'windBeaufort',
					}, // 只数字
					WindDir: 'windDirection',
					Sea: {
						spilt: 'number',
						data: 'seasHeight',
					},
					SwellHeight: {
						spilt: 'number',
						data: 'swellHeight',
					},
					SwellDir: 'swellDirection',
					MeHfoSLR: {
						spilt: 'number',
						data: 'meConsSlrHfo',
					},
					MeLsfoSLR: {
						spilt: 'number',
						data: 'meConsSlrLsfo',
					},
					MeMdoSLR: {
						spilt: 'number',
						data: 'meConsSlrMdo',
					},
					MeMgoSLR: {
						spilt: 'number',
						data: 'meConsSlrMgo',
					},
					AuxHfoSLR: {
						spilt: 'number',
						data: 'aeConsSlrHfo',
					},
					AuxLsfoSLR: {
						spilt: 'number',
						data: 'aeConsSlrLsfo',
					},
					AuxMdoSLR: {
						spilt: 'number',
						data: 'aeConsSlrMdo',
					},
					AuxMgoSLR: {
						spilt: 'number',
						data: 'aeConsSlrMgo',
					},
					TimeOfNextPort: 'nextPortEta',
					NameOfNextPort: {
						spilt: ' | ',
						data: ['nextPortNextPort', 'nextPortUnlocode'],
					},
				},
				EOSP: {
					// cosp复制过来的
					ShipName: 'vesselName',
					IMOnum: 'imoNum',
					Callsign: 'callSign',
					COSPDateTime: 'cospDate',
					DraftFwd: {
						spilt: 'number',
						data: 'foreDraft',
					},
					DraftAft: {
						spilt: 'number',
						data: 'aftDraft',
					},
					MeanDraft: {
						spilt: 'number',
						data: 'midDraft',
					},
					LoadingCond: 'load',
					GmHeight: {
						spilt: 'number',
						data: 'gm',
					},
					CargoName: 'cargo',
					CargoWeight: 'cargoWeight',
					Displacement: {
						spilt: 'number',
						data: 'displancement',
					},
					DistArrPilot: {
						spilt: 'number',
						data: 'distanceToGo',
					},
					BROBHFO: {
						spilt: 'number',
						data: 'brobHfo',
					},
					BROBLSFO: {
						spilt: 'number',
						data: 'brobLsfo',
					},
					BROBMDO: {
						spilt: 'number',
						data: 'brobMdo',
					},
					BROBMGO: {
						spilt: 'number',
						data: 'brobMgo',
					},
					IntdSpeed: {
						spilt: 'number',
						data: 'orderedSpeed',
					},
					// 独有
					PositionEOSP: {
						LAT: ['positionLatitudeLeft', 'positionLatitudeCenter', 'positionLatitudeRight'],
						LON: ['longitudeLeft', 'longitudeCenter', 'longitudeRight'],
					},
					ReportDateTime: 'positionDate',
					AvgSpeed: {
						spilt: 'number',
						data: 'avgSpeedOverGroundSlr',
					},
					Course: {
						spilt: 'number',
						data: 'course',
					},
					DistSLR: {
						spilt: 'number',
						data: 'distanceSlr',
					},
					DistRemain: {
						spilt: 'number',
						data: 'distanceToGo',
					},
					AvgRPM: {
						spilt: 'number',
						data: 'avgRpmSlr',
					}, // 数组
					Slip: {
						spilt: 'number',
						data: 'slipSlr',
					},
					SteamTimeSLR: {
						spilt: 'number',
						data: 'timeSlr',
					},
					MeLoadSLR: {
						spilt: 'number',
						data: 'mcr'
					},
					WindBF: {
						spilt: 'number',
						data: 'windBeaufort',
					}, // 只数字
					WindDir: 'windDirection',
					Sea: {
						spilt: 'number',
						data: 'seasHeight',
					},
					SwellHeight: {
						spilt: 'number',
						data: 'swellHeight',
					},
					SwellDir: 'swellDirection',
					MeHfoSLR: {
						spilt: 'number',
						data: 'meConsSlrHfo',
					},
					MeLsfoSLR: {
						spilt: 'number',
						data: 'meConsSlrLsfo',
					},
					MeMdoSLR: {
						spilt: 'number',
						data: 'meConsSlrMdo',
					},
					MeMgoSLR: {
						spilt: 'number',
						data: 'meConsSlrMgo',
					},
					AuxHfoSLR: {
						spilt: 'number',
						data: 'aeConsSlrHfo',
					},
					AuxLsfoSLR: {
						spilt: 'number',
						data: 'aeConsSlrLsfo',
					},
					AuxMdoSLR: {
						spilt: 'number',
						data: 'aeConsSlrMdo',
					},
					AuxMgoSLR: {
						spilt: 'number',
						data: 'aeConsSlrMgo',
					},
					TimeOfNextPort: 'nextPortEta',
					NameOfNextPort: {
						spilt: ' | ',
						data: ['nextPortNextPort', 'nextPortUnlocode'],
					},
					ArrPort: {
						spilt: ' | ',
						data: ['arrivalPosition', 'unlocode'],
					},
					EOSPDateTime: 'eospData',
					DistTotal: {
						spilt: 'number',
						data: 'distanceSinceCosp',
					},
					ECATimeTotal: {
						spilt: 'number',
						data: 'timeInEca',
					},
					ECADistTotal: {
						spilt: 'number',
						data: 'distanceInEca',
					},
				},
				Drift: {
					// cosp复制过来的
					ShipName: 'vesselName',
					IMOnum: 'imoNum',
					Callsign: 'callSign',
					// 独有
					DriftStartPosition: {
						LAT: ['stopPositionLatitudeLeft', 'stopPositionLatitudeCenter', 'stopPositionLatitudeRight'],
						LON: ['stopPositionLongitudeLeft', 'stopPositionLongitudeCenter', 'stopPositionLongitudeRight'],
					},
					DriftEndPosition: {
						LAT: ['resumePositionLatitudeLeft', 'resumePositionLatitudeCenter', 'resumePositionLatitudeRight'],
						LON: ['resumePositionLongitudeLeft', 'resumePositionLongitudeCenter', 'resumePositionLongitudeRight'],
					},
					DriftStartTime: 'stopDate',
					DriftEndTime: 'resumeData',
					DistSLRatStop: {
						spilt: 'number',
						data: 'distSlrAtStop',
					},
					DistRemainatResume: {
						spilt: 'number',
						data: 'distRemainAtResume',
					},
					BROBHFOatStop: {
						spilt: 'number',
						data: 'stopBrobHfo',
					},
					BROBLSFOatStop: {
						spilt: 'number',
						data: 'stopBrobLsfo',
					},
					BROBMDOatStop: {
						spilt: 'number',
						data: 'stopBrobMdo',
					},
					BROBMGOatStop: {
						spilt: 'number',
						data: 'stopBrobMgo',
					},
					BROBHFOatResume: {
						spilt: 'number',
						data: 'resumeBrobHfo',
					},
					BROBLSFOatResume: {
						spilt: 'number',
						data: 'resumeBrobLsfo',
					},
					BROBMDOatResume: {
						spilt: 'number',
						data: 'resumeBrobMdo',
					},
					BROBMGOatResume: {
						spilt: 'number',
						data: 'resumeBrobMgo',
					},
					Remarks: 'stopReason',
				},
			},
			options: [
				{
					label: 'COSP',
					value: 'COSP',
					addType: 'cosp',
					saveFile: 'masterReportCosp',
				},
				{
					label: 'Noon Report',
					value: 'Noon Report',
					addType: 'noonReport',
					saveFile: 'masterReportNoonReport',
				},
				{
					label: 'EOSP',
					value: 'EOSP',
					addType: 'eosp',
					saveFile: 'masterReportEosp',
				},
				{
					label: 'Stoppage report',
					value: 'Drift',
					addType: 'stoppageReport',
					saveFile: 'masterReportStoppageReport',
				},
			],
			reportType: 'COSP',
			form: {},
			allData: null,
		};
	},
	async mounted() {
		await this.getReport();
		this.reportType = this.$parent.addReportSelectValue;
		this.form = this.$parent.addReportForm;
		this.textarea = this.form?.value;
		this.allData = this.$parent.addReportAllData;
		console.log(this.isEdit, this.allData);
	},
	methods: {
		// 获取日报
		async getReport() {
			this.tableData = [];
			// 移除日报图层
			// let data = this.simulationData.cpData.filter((item) => item.ld === this.typeBL[0]);
			// if (!data.length) {
			// 	return
			// }
			this.reportTable = await getMasterReport({
				id: this.customerId,
				type: this.typeBL[1],
			});
		},
		// 从港报cosp自动带入船舶信息
		updateFormByReportTable() {
			let data;
			if (this.reportType !== 'COSP') {
				for (let index = 0; index < this.reportTable.length; index++) {
					const element = this.reportTable[index];
					if (element.masterReportCosp) {
						data = element.masterReportCosp;
						break;
					}
				}
			}
			if (data) {
				setTimeout((e) => {
					this.$refs[this.reportType].setData('1');
					this.$refs[this.reportType].form.vesselName = data.vesselName;
					this.$refs[this.reportType].form.imoNum = data.imoNum;
					this.$refs[this.reportType].form.callSign = data.callSign;
				}, 400);
			}
		},
		closeCluster() {
			this.$emit('close');
		},
		// 保存
		async saveData() {
			let typeSon = this.options.find((e) => e.value === this.reportType).addType;
			let saveFile = this.options.find((e) => e.value === this.reportType).saveFile;
			// let data = this.simulationData.cpData.find((item) => item.ld === this.typeBL[0])
			// if (!data.length) {
			// 	return
			// }
			let params = {
				typeSon: typeSon,
				type: this.typeBL[1],
				parentId: this.customerId,
				voyageNo: this.voyageNo, // todo
			};
			for (let index = 0; index < mustFields.length; index++) {
				const element = mustFields[index];
				if (element in this.$refs[this.reportType].form) {
					if (!this.$refs[this.reportType].form[element] && this.$refs[this.reportType].form[element] !== 0) {
						if (['distanceSlr'].includes(element) && this.reportType === 'Drift') {
						} else {
							console.log(element);
							this.$message.info('Required information is empty!');
							return;
						}
					}
				}
			}
			this.$refs[this.reportType].form.value = this.textarea;
			params[saveFile] = this.$refs[this.reportType].form;
			console.log(params);
			if (this.isEdit && this.allData) {
				// 编辑
				this.allData[saveFile] = this.$refs[this.reportType].form;
				params['value'] = this.textarea;
				if (this.allData.masterReportInfo.seg) {
					this.allData.masterReportInfo.seg = this.allData.masterReportInfo.seg.toString();
				}
				let res = await updateMasterReport({
					...this.allData,
					updateType: 'top',
				});
				// tudo
				// 更新成功
				if (res?.success) {
					// 编辑结束更新右侧表格
					this.$bus.$emit('editReportTable', res.data);
					this.$emit('close');
					this.$message.success('update success!');
				}
			} else {
				// 新增
				addMasterReport(params).then((res) => {
					this.$bus.$emit('editReportTable', res.data);
					this.$emit('close');
					this.$message.success('save success!');
				});
			}
		},
		clearAll() {
			this.textarea = '';
			this.$refs[this.reportType].clear();
		},
		utcToTime(originalDateTimeString) {
			originalDateTimeString = originalDateTimeString.replace(':', '').replace(/\s/g, '');
			// 原始日期时间字符串
			// 提取年、月、日、时、分
			const year = originalDateTimeString.slice(0, 4);
			const month = originalDateTimeString.slice(4, 6);
			const day = originalDateTimeString.slice(6, 8);
			const hour = originalDateTimeString.slice(9, 11);
			const minute = originalDateTimeString.slice(11, 13);
			// 构建新的日期时间字符串
			const formattedDateTimeString = `${year}-${month}-${day} ${hour}:${minute}`;
			// 判断 formattedDateTimeString 是否符合时间格式。如果不符合，则将其赋值为空
			if (isNaN(Date.parse(formattedDateTimeString))) {
				return "";
			}
			return formattedDateTimeString;
		},
		IdentifyData() {
			if (!this.textarea) return;
			console.log('🚀 ~ IdentifyData ~ this.textarea:', this.textarea);
			const obj = {};
			const regex = /\[(.*?)\]:(.*?)(?=\n\[\w+\]|$)/gs;
			let match;
			while ((match = regex.exec(this.textarea))) {
				const key = match[1].trim();
				const value = match[2].trim();
				obj[key] = value;
			}
			console.log('🚀 ~ IdentifyData ~ obj:', obj);
			this.reportType = obj.Type;
			let dictionary = this.dictionary[obj.Type];
			setTimeout((e) => {
				this.form = {};
				for (let key in obj) {
					// 时间
					if (
						[
							'ReqETA',
							'COSPDateTime',
							'ReportDateTime',
							'TimeOfNextPort',
							'EOSPDateTime',
							'DriftStartTime',
							'DriftEndTime',
						].includes(key)
					) {
						this.form[dictionary[key]] = this.utcToTime(obj[key]);
						console.log(this.form[dictionary[key]]);
					} else if (
						[
							'PosArrPilot',
							'Position',
							'DriftStartPosition',
							'DriftEndPosition',
							'PositionCOSP',
							'PositionEOSP',
						].includes(key)
					) {
						// 原始经纬度字符串
						const originalCoordinatesString = obj[key];
						// 使用正则表达式匹配经度和纬度的部分
						const regex = /(\d+)°(\d+\.\d+)([NSEWnsew])\s+(\d+)°(\d+\.\d+)([NSEWnsew])/;
						const matches = originalCoordinatesString.match(regex);
						if (matches) {
							// 拆分经度和纬度
							const latitude = [matches[1] * 1, matches[2] * 1, matches[3].toUpperCase()];
							const longitude = [matches[4] * 1, matches[5] * 1, matches[6].toUpperCase()];
							if (dictionary[key]?.LAT && dictionary[key]?.LON) {
								dictionary[key].LAT.forEach((e, index) => (this.form[e] = latitude[index]));
								dictionary[key].LON.forEach((e, index) => (this.form[e] = longitude[index]));
							}
						}
						// this.form[dictionary[key]] = obj[key]
					} else if (Array.isArray(dictionary[key])) {
						// 数组
						dictionary[key].forEach((e) => {
							const matches = obj[key].match(/\d+(\.\d+)?/);
							this.form[e] = matches ? parseFloat(matches[0]).toPrecision(12) * 1 : null;
						});
					} else if (typeof dictionary[key] === 'object') {
						// 对象
						if (dictionary[key].spilt === 'number') {
							const matches = obj[key].replace(',', '').match(/\d+(\.\d+)?/);
							this.form[dictionary[key].data] = matches ? parseFloat(matches[0]).toPrecision(12) * 1 : 0;
						} else {
							let arr = obj[key].split(dictionary[key].spilt);
							this.form[dictionary[key].data[0]] = arr[0];
							this.form[dictionary[key].data[1]] = arr[1];
						}
					} else if (dictionary[key]) {
						if (key === 'SwellDir') {
							console.log(obj[key]);
						}
						// 直接赋值
						this.form[dictionary[key]] = obj[key];
					} else if ('DepPort' === key) {
						// 通过|拆分
						let arr = obj[key].split(' | ');
						this.form['departurePort'] = arr[0];
						this.form['unlocode'] = arr[1];
					} else if ('ArrPort' === key) {
						let arr = obj[key].split(' | ');
						this.form['nextPortNextPort'] = arr[0];
						this.form['nextPortUnlocode'] = arr[1];
					}
				}
				console.log(this.form);
			}, 400);
		},
	},
};
</script>

<style lang="less" scoped>
.report {
	position: absolute;
	z-index: 1000;
	background: rgba(255, 255, 255);
	width: 100%;
	height: 100vh;
	padding: 10px;
	top: 0;

	.complianceHeader {
		height: 50px;
		font-size: 16px;

		.title {
			line-height: 50px;
			font-weight: bold;
			color: #000;
			padding-left: 20px;
			float: left;
		}

		.closeBtn {
			position: relative;
			right: 15px;
			top: 9px;
			float: right;
			cursor: pointer;
		}

		.clearBtn {
			line-height: 50px;

			.el-button {
				margin-left: 10px;
			}
		}
	}

	.reportContent {
		height: calc(100% - 50px);
		width: 100%;
		display: flex;

		.left {
			width: 20%;
			height: 100%;
		}

		.right {
			width: 80%;
			height: 100%;

			.reportselect {
				width: 220px;
				height: 32px;
			}

			.reportTypeContent {
				width: 100%;
				height: 95%;
				padding: 0 15px;
				overflow: auto;
			}
		}

		.button {
			font-size: 12px;
			width: 120px;
			height: 32px;
			line-height: 32px;
			border-radius: 6px;
			background: #fff;
			color: #355691;
			border: 1px solid #d9d9d9;
			cursor: pointer;
			text-align: center;
			font-size: 14px;
			font-weight: 500;
			margin-right: 5px;
		}

		.save {
			background: rgba(53, 86, 145, 1);
			color: #fff;
		}
	}
}

::v-deep .el-textarea {
	height: 95% !important;

	.el-textarea__inner {
		height: 100% !important;
	}
}
</style>
